.cookie-modal {
  color: #253B48;
  background-color: #f7f7f7;
  box-sizing: border-box;
  padding: 32px;
  border-radius: 5px;
  pointer-events: auto;
  border: 0px;
  position: fixed;
  left: 30%;
  bottom: 0px;
  width: 600px;
  max-width: calc(100% - 32px);
  transform: translate(0, -20px);
  animation: silktide-slideInDown 350ms ease-out forwards;
  animation-delay: 0.3s;
  box-shadow: -5px 5px 10px 0px #00000012, 0px 0px 50px 0px #0000001a;
  z-index: 1000;
}

.cookie-modal-content {
  max-width: 800px;
  text-align: center;
}

.cookie-modal-content p {
  margin: 0 0 12px 0;
  font-size: 14px;
  color: #333;
}

.cookie-modal-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.cookie-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgb(255, 106, 19);
  border-radius: 100%;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
}

.preferences-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.preferences-modal {
  top: 0;
  bottom: auto;
  height: auto;
}
